import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "text"
    }}>{`Text`}</h1>
    <h2 {...{
      "id": "font"
    }}>{`Font`}</h2>
    <ContentBlock imgName="Text-font.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    @frozen struct Font
    usage: Text("Hello World").font(.option) -> Text
*/
VStack(alignment: .trailing, spacing: 10) {
    Text("Text.font()")
        .bold()
        .padding([.vertical])
    
    Text(".largeTitle")
        .font(.largeTitle)
    
    Text(".title")
        .font(.title)
    
    Text(".headline")
        .font(.headline)
    
    Text(".subheadline")
        .font(.subheadline)
    
    Text(".body")
        .font(.body)
    
    Text(".callout")
        .font(.callout)
    
    Text(".caption")
        .font(.caption)
    
    Text(".footnote")
        .font(.footnote)
}
.font(.title)
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "decoration-modifers"
    }}>{`Decoration Modifers`}</h2>
    <p><inlineCode parentName="p">{`underline()`}</inlineCode>{` and `}<inlineCode parentName="p">{`strikethrough()`}</inlineCode>{` take optional `}<inlineCode parentName="p">{`active`}</inlineCode>{` and `}<inlineCode parentName="p">{`Color`}</inlineCode>{` params. `}</p>
    <ContentBlock imgName="Text-decorationModifiers.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    func bold() -> Text
    func italic() -> Text
    func underline(_ active: Bool = true, color: Color? = nil) -> Text
    func strikethrough(_ active: Bool = true, color: Color? = nil) -> Text
*/
VStack(alignment: .trailing, spacing: 10) {
    Text("Text Decoration Modifiers")
        .padding([.vertical])
    
    Text(".bold()")
        .bold()
    
    Text(".italic()")
        .italic()
    
    Text(".underline()")
        .underline()
    
    Text(".underline(color: .red)")
        .underline(color: .red)
    
    Text(".strikethrough()")
        .strikethrough()
    
    Text(".strikethrough(false)")
        .strikethrough(false)
}
.font(.title)
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "fontweight"
    }}>{`fontWeight()`}</h2>
    <ContentBlock imgName="Text-fontWeight.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    func fontWeight(_ weight: Font.Weight?) -> Text 
*/
VStack(alignment: .trailing, spacing: 10) {
    Text("Text.fontWeight()")
        .padding([.vertical])
    
    Text(".black")
        .fontWeight(.black)
    
    Text(".heavy")
        .fontWeight(.heavy)
    
    Text(".semibold")
        .fontWeight(.semibold)
    
    Text(".medium")
        .fontWeight(.medium)
    
    Text(".regular")
        .fontWeight(.regular)
    
    Text(".thin")
        .fontWeight(.thin)
    
    Text(".ultralight")
        .fontWeight(.ultraLight)
}
.font(.title)
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "baselineoffset"
    }}>{`baselineOffset()`}</h2>
    <p>{`Vertical offset.`}</p>
    <ContentBlock imgName="Text-baselineOffset.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    func func baselineOffset(_ baselineOffset: CGFloat) -> Text
*/
VStack(alignment: .trailing, spacing: 10) {
    Text("Text.baselineOffset()")
        .bold()
        .padding([.vertical])
    
    Text(".baselineOffset(50.5)")
        .baselineOffset(50)
        .border(Color.red, width: 3)
    
    Text(".baselineOffset(-50)")
        .baselineOffset(-50)
        .border(Color.red, width: 3)
}
.font(.title)
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "tracking"
    }}>{`tracking()`}</h2>
    <p>{`Letter-spacing, including the separation of ligatures. Supercedes `}<inlineCode parentName="p">{`kerning`}</inlineCode>{` if both are used. `}</p>
    <ContentBlock imgName="Text-tracking.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    func tracking(_ tracking: CGFloat) -> Text
*/
VStack(alignment: .trailing, spacing: 10) {
    Text("Text.baselineOffset()")
        .bold()
        .padding([.vertical])
    
    Text("No Tracking")
    
    Text(".tracking(1)")
        .tracking(1)
    
    Text(".tracking(3)")
        .tracking(3)
    
    Text(".tracking(-2.5)")
        .tracking(-2.5)
}
.font(.title)
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "kerning"
    }}>{`kerning()`}</h2>
    <p>{`Letter-spacing, including trailing whitespace. Superceded by `}<inlineCode parentName="p">{`tracking`}</inlineCode>{` if both are used. `}</p>
    <ContentBlock imgName="Text-kerning.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    func tracking(_ tracking: CGFloat) -> Text
*/
VStack(alignment: .trailing, spacing: 10) {
    Text("Text.kerning()")
        .bold()
        .padding([.vertical])
    
    Text("No Kerning")
    
    Text(".kerning(1)")
        .kerning(1)
    
    Text(".kerning(3)")
        .kerning(3)
    
    Text(".kerning(-2.5)")
        .kerning(-2.5)
}
.font(.title)
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Text("Hello world").foregroundColor(.red) // makes text red
Text("Hello world").backgroundColor(.yellow) // makes background yellow
`}</code></pre>
    <h2 {...{
      "id": "operations"
    }}>{`Operations`}</h2>
    <p>{`Checking equality `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Text("Hello") == Text("hello") // false 
Text("Hello") != Text("hello") // true 
`}</code></pre>
    <p>{`Concatenation `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Text("Hello ") + Text("world") // "Hello world"
`}</code></pre>
    <h2 {...{
      "id": "interpolation"
    }}>{`Interpolation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`let year = 2038
Text("The year is: \\(year)") // "The year is 2038"
`}</code></pre>
    <h2 {...{
      "id": "alignment"
    }}>{`Alignment`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Text("Consider setting alignment on the containing stack view as well")
    .multilineTextAlignment(.center) // .leading, .center, or .trailing
`}</code></pre>
    <h2 {...{
      "id": "truncation"
    }}>{`Truncation`}</h2>
    <p>{`Text can have line limits, which can truncate the text with ellipses ("...")`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Text("Actual truncation behavior will depend on containing view size")
    .lineLimit(2)
`}</code></pre>
    <h3 {...{
      "id": "resources"
    }}>{`Resources`}</h3>
    <a rel="noopener noreferrer" href="https://developer.apple.com/documentation/swiftui/text" target="_blank">Apple Developer Documentation</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      